import React from "react";
import Hero from "./hero";
import MusicLicensing from "./music-licensing";
import { MusicContextProvider } from "@/src/contexts/MusicContext";
import AiMastering from "./ai-mastering";
import StemsAndInstrumentals from "./stems-and-instrumentals";
import SuperchargeYourMusic from "./supercharge-your-music";
import RadioEdits from "./radio-edits";
import AiArtwork from "./ai-artwork";
import TranscribeLyrics from "./transcribe-lyrics";
import Metadata from "./metadata";
import FeaturedArtists from "./featured-artists";
import SignupAndTestimonials from "./signup-and-testimonials";
import Newsletter from "./newsletter";
import Footer from "../navigation/footer";
import { HomepageSchema } from "@/lib/types/homepage-schema";

export type HomePageProps = {
  schema: HomepageSchema;
};

const HomePage = ({ schema }: HomePageProps) => {
  // TODO(#1602): Generating static pages is broken by not having real data.
  if (schema?.hero?.images === undefined) {
    if (process.env.NEXT_PHASE === "phase-production-build") {
      console.warn("Skipping HomePage because schema?.hero?.images is undefined during static page generation. Please see #1602 for more details.");
      return <></>;
    }
  }
  return (
    <MusicContextProvider>
      <Hero
        className="mt-[70px]"
        heroArtists={schema.hero.images}
        heroArtistsChangeInterval={schema.hero.imageFlip}
        words={schema.hero.titles}
        wordsChangeInterval={schema.hero.titleFlip}
        wordsDelayCycleEnd={schema.hero.titleSleep}
        quotes={schema.testimonials.examples}
        quotesChangeInterval={schema.testimonials.exampleFlip}
      />

      <MusicLicensing
        musicLicenses={schema.marketplace.examples}
        changeInterval={schema.marketplace.exampleFlip}
      />

      <AiMastering
        aiMasteringEntities={schema.mastering.examples}
        changeInterval={schema.mastering.exampleFlip}
      />

      <StemsAndInstrumentals
        stemsSongs={schema.stems.examples}
        changeInterval={schema.stems.exampleFlip}
      />

      <SuperchargeYourMusic />

      <RadioEdits
        lyrics={schema.radio.examples}
        changeInterval={schema.radio.exampleFlip}
      />

      <AiArtwork
        artworkPrompts={schema.artwork.examples}
        changeInterval={schema.artwork.exampleFlip}
      />

      <TranscribeLyrics
        lyrics={schema.lyrics.examples}
        changeInterval={schema.lyrics.exampleFlip}
      />

      <Metadata />

      <FeaturedArtists
        featuredArtists={schema.featured?.examples ?? []}
        changeInterval={schema.featured?.exampleFlip ?? 0}
        backgroundUrl={schema.featured?.backgroundUrl ?? ""}
      />

      <SignupAndTestimonials
        testimonials={schema.testimonials.examples}
        changeInterval={schema.testimonials.exampleFlip}
      />

      <Newsletter />

      <Footer />
    </MusicContextProvider>
  );
};

export default HomePage;
